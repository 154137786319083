import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { blue, pink } from "@material-ui/core/colors";
import * as React from "react";
import "./App.css";
import { Page } from "./components/Page";

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink
  }
});

export class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Page />
      </MuiThemeProvider>
    );
  }
}
