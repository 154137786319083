import Moment from "moment";
import * as React from "react";
import { Popup } from "react-map-gl";
import { BusVehicle } from "./Map";
interface BusVehiclePopupProps {
  busVehicle: BusVehicle;
  onClose(): void;
}

// TODO: display stop name instead of stop id.
export const BusVehiclePopup: React.FunctionComponent<BusVehiclePopupProps> = (
  props: BusVehiclePopupProps
) => {
  const { busVehicle, onClose } = props;
  return (
    <Popup
      tipSize={5}
      anchor="top"
      longitude={busVehicle.position.longitude}
      latitude={busVehicle.position.latitude}
      closeOnClick={false}
      onClose={onClose}
    >
      <h3>License ID: {busVehicle.vehicle.license_plate}</h3>
      Nearest Stop: {busVehicle.stop_id}
      <p> Update: {Moment.unix(busVehicle.timestamp).format("LT")} </p>
    </Popup>
  );
};
