import * as React from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import { BusStopDrawer } from "./BusStopDrawer";
import { BusStopPin } from "./BusStopPin";
import { BusVehiclePin } from "./BusVehiclePin";
import { BusVehiclePopup } from "./BusVehiclePopup";
import { PolylineOverlay } from "./PolylineOverlay";

interface MapProps {
  route?: number;
  busRoute: {
    busStops: BusStop[];
    busRouteShapes: BusRouteShapes[];
  };
  busVehicles: BusVehicle[];
  dir_selected: string[];
  showMessage(message: string): void;
  closeMessage(): void;
}

export interface BusStop {
  lat: number;
  lng: number;
  stopID: string;
  stopName: string;
  dir_abbr: string;
}

export interface BusRouteShapes {
  lat: number;
  lng: number;
  dir_abbr: string;
}

export interface ArrivalTime {
  vehicle_info: {
    vehicle: BusVehicle;
  };
  arrival_time: string;
}

interface BusStopArrivalTime {
  busStop: BusStop;
  arrivalTimes: ArrivalTime[];
}

export interface BusVehicle {
  timestamp: number;
  stop_id: number;
  trip: {
    trip_id: string;
    dir_abbr: string;
    route_id: string;
  };
  position: {
    latitude: number;
    longitude: number;
    speed: number;
    bearing: number;
  };
  vehicle: { license_plate: string; id: string };
}

const iconSize = {
  width: 24,
  height: 27
};

interface MapState {
  popUpBusStop?: BusStopArrivalTime;
  popUpBusVehicle?: BusVehicle;
  viewport: any;
}

const defaultCenter: Coordinate = [-97.7431, 30.2672];
export declare type Coordinate = [number, number];

export class Map extends React.Component<MapProps, MapState> {
  constructor(props: MapProps) {
    super(props);
    this.state = {
      viewport: {
        width: "100%",
        height: "75%",
        latitude: 30.2672,
        longitude: -97.7431,
        zoom: 11.5
      },
      popUpBusStop: undefined,
      popUpBusVehicle: undefined
    };
  }

  public render() {
    const g = document.getElementsByTagName("body")[0];
    const heightValue =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      g.clientHeight;

    return (
      <ReactMapGL
        {...this.state.viewport}
        mapStyle={"mapbox://styles/mapbox/streets-v9"}
        onViewportChange={viewport => this.setState({ viewport })}
      >
        {this.renderBusStops()}
        {this.renderBusStopPopUp()}

        {this.renderBusVehicles()}
        {this.renderBusVehiclePopUp()}

        <PolylineOverlay points={this.getBusRouteShapeCoordinates()} />
      </ReactMapGL>
    );
  }

  /**
   * Render Bus Route
   */

  private renderBusStops = () => {
    return this.props.busRoute.busStops.map(busStop =>
      this.renderBusStop(busStop, this.props.dir_selected)
    );
  };

  private renderBusStop = (busStop: BusStop, dir_selected: string[]) => {
    if (dir_selected.includes(busStop.dir_abbr)) {
      return (
        <Marker
          longitude={busStop.lng}
          latitude={busStop.lat}
          key={busStop.stopID}
          offsetLeft={-iconSize.width / 2}
          offsetTop={-iconSize.height}
        >
          <BusStopPin
            color={
              busStop.dir_abbr === dir_selected[0] ? "secondary" : "primary"
            }
            onClick={() => {
              this.fetchArrivalTime(busStop);
            }}
          />
        </Marker>
      );
    }
  };

  private fetchArrivalTime = (busStop: BusStop) => {
    this.props.showMessage("Loading arrival times...");
    fetch(`/arrival_times/${this.props.route}/${busStop.stopID}`).then(
      response => {
        response.json().then(arrivalTimes => {
          this.props.closeMessage();
          this.setState({
            popUpBusStop: { busStop, arrivalTimes: arrivalTimes.arrival_times }
          });
        });
      }
    );
  };

  private renderBusStopPopUp() {
    if (this.state.popUpBusStop) {
      const { busStop, arrivalTimes } = this.state.popUpBusStop;
      return (
        busStop && (
          <BusStopDrawer
            busStop={busStop}
            arrivalTimes={arrivalTimes}
            open={this.state.popUpBusStop !== null}
            onClose={() => {
              this.setState({
                popUpBusStop: undefined
              });
            }}
          />
        )
      );
    }
  }

  private getBusRouteShapeCoordinates = (): Coordinate[] => {
    return this.props.busRoute.busRouteShapes
      .filter(busRouteShape => {
        return this.props.dir_selected.includes(busRouteShape.dir_abbr);
      })
      .map(busRouteShape => {
        return [busRouteShape.lng, busRouteShape.lat] as Coordinate;
      });
  };

  /**
   * Render Bus Vehicles
   */

  private renderBusVehicles = () => {
    return this.props.busVehicles
      .filter(busVehicle => {
        return this.props.dir_selected.includes(busVehicle.trip.dir_abbr);
      })
      .map(busVehicle => {
        const bearing = this.readBearing(busVehicle.position.bearing);
        return (
          <Marker
            longitude={busVehicle.position.longitude}
            latitude={busVehicle.position.latitude}
            key={busVehicle.vehicle.id}
            offsetLeft={-iconSize.width / 2}
            offsetTop={-iconSize.height}
          >
            <BusVehiclePin
              bearing={bearing}
              onClick={() => this.setState({ popUpBusVehicle: busVehicle })}
            />
          </Marker>
        );
      });
  };

  private renderBusVehiclePopUp = () => {
    const { popUpBusVehicle } = this.state;

    return (
      popUpBusVehicle && (
        <BusVehiclePopup
          busVehicle={popUpBusVehicle}
          onClose={() => this.setState({ popUpBusVehicle: undefined })}
        />
      )
    );
  };

  private readBearing = (bearing: number) => {
    let bearingStr: string = "N";
    const binHalfWidth: number = 45 / 2;
    const bearingList: string[] = ["NE", "E", "SE", "S", "SW", "W", "NW"];
    for (let i = 0; i < bearingList.length; i++) {
      if (
        bearing >= 45 * (i + 1) - binHalfWidth &&
        bearing < 45 * (i + 1) + binHalfWidth
      ) {
        bearingStr = bearingList[i];
      }
    }
    return bearingStr;
  };
}
