import {
  createStyles,
  Divider,
  Drawer as MaterialDrawer,
  IconButton,
  Theme,
  withStyles
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import classNames from "classnames";
import * as React from "react";
import { ClassDictionary } from "../interfaces/ClassDictionary";
import { drawerWidth } from "./Page";

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: 0,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing.unit * 9 + 1
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    }
  });

interface DrawerProps {
  classes: ClassDictionary;
  drawerOpen: boolean;
  theme: any;
  children: any;
  handleDrawerClose(): void;
}

const PureDrawer: React.FunctionComponent<DrawerProps> = (
  props: DrawerProps
) => {
  const { classes, drawerOpen, handleDrawerClose, theme } = props;

  return (
    <MaterialDrawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen
        })
      }}
      open={drawerOpen}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      {props.children}
    </MaterialDrawer>
  );
};

export const Drawer = withStyles(styles, { withTheme: true })(PureDrawer);
