import { PropTypes } from "@material-ui/core";
import LocationIcon from "@material-ui/icons/LocationOn";
import React, { PureComponent } from "react";

interface BusStopPinProps {
  color?: PropTypes.Color | "action" | "disabled" | "error";
  onClick(): void;
}

export class BusStopPin extends PureComponent<BusStopPinProps> {
  public render() {
    const { onClick, color } = this.props;
    return <LocationIcon onClick={onClick} color={color} />;
  }
}
