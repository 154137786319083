import {
  AppBar as MaterialAppBar,
  Avatar,
  Badge,
  Chip,
  createStyles,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  withStyles
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import * as React from "react";
import { ClassDictionary } from "../interfaces/ClassDictionary";
import { HelpDialog } from "./HelpDialog";
import { drawerWidth, RouteInfo } from "./Page";

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    hide: {
      display: "none"
    },
    grow: {
      flexGrow: 1
    }
  });

interface AppBarProps {
  route?: RouteInfo;
  classes: ClassDictionary;
  drawerOpen: boolean;
  handleDrawerOpen(): void;
}

interface AppBarState {
  helpDialogOpen: boolean;
}

class PureAppBar extends React.Component<AppBarProps, AppBarState> {
  constructor(props: AppBarProps) {
    super(props);
    this.state = {
      helpDialogOpen: false
    };
  }

  public render() {
    const { classes, drawerOpen, handleDrawerOpen, route } = this.props;
    const { helpDialogOpen } = this.state;

    return (
      <MaterialAppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: drawerOpen
        })}
      >
        <Toolbar disableGutters={!drawerOpen}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classNames(classes.menuButton, {
              [classes.hide]: drawerOpen
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap={true}>
            Austin Bus Go
          </Typography>
          {route ? (
            <Chip
              style={{ marginLeft: 16 }}
              color="secondary"
              label={route.name}
              avatar={<Avatar>{route.id}</Avatar>}
            />
          ) : null}

          <div className={classes.grow} />
          <IconButton
            color="inherit"
            style={{ marginRight: 20 }}
            onClick={this.openHelpDialog}
          >
            <HelpIcon />
          </IconButton>
        </Toolbar>
        <HelpDialog onClose={this.closeHelpDialog} open={helpDialogOpen} />
      </MaterialAppBar>
    );
  }

  private openHelpDialog = () => {
    this.setState({ helpDialogOpen: true });
  };

  private closeHelpDialog = () => {
    this.setState({ helpDialogOpen: false });
  };
}

export const AppBar = withStyles(styles, { withTheme: true })(PureAppBar);
