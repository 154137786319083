import {
  Avatar,
  createStyles,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Theme,
  withStyles
} from "@material-ui/core";
import * as React from "react";
import { AppBar } from "./AppBar";
import { CheckboxLabels } from "./CheckboxLabels";
import { Drawer } from "./Drawer";
import { BusRouteShapes, BusStop, BusVehicle, Map } from "./Map";

export const drawerWidth = 300;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%"
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1
    }
  });

export interface RouteInfo {
  id: number;
  name: string;
  color: string;
}

interface PageState {
  route?: RouteInfo;
  busRoute: {
    busStops: BusStop[];
    busRouteShapes: BusRouteShapes[];
  };
  allRoutes?: RouteInfo[];
  busVehicles: BusVehicle[];
  checked1: boolean;
  checked2: boolean;
  dir1: string;
  dir2: string;
  open: boolean;
  drawerOpen: boolean;
  message: string;
}

export class PurePage extends React.Component<any, PageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      busRoute: {
        busStops: [],
        busRouteShapes: []
      },
      busVehicles: [],
      route: undefined,
      checked1: true,
      checked2: false,
      dir1: "",
      dir2: "",
      open: false,
      drawerOpen: false,
      message: ""
    };
  }

  public componentDidMount() {
    // Call the backend and load all available routes here.
    fetch("/all_route_ids").then(response => {
      response.json().then(routeIds => {
        // Construct menu items array
        this.setState({ allRoutes: routeIds.all_routes });
      });
    });
  }

  public handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  public handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  public setRoute = (route: RouteInfo): void => {
    this.setState({ route, drawerOpen: false }, () =>
      this.showMessage(`Loading route ${route.name}...`)
    );
    this.loadRouteData(route.id);
  };

  public render() {
    const { classes } = this.props;
    const directionLoaded = this.state.dir1 !== "";
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          route={this.state.route}
          drawerOpen={this.state.drawerOpen}
          handleDrawerOpen={this.handleDrawerOpen}
        />
        <Drawer
          drawerOpen={this.state.drawerOpen}
          handleDrawerClose={this.handleDrawerClose}
        >
          <Divider />
          <List>
            {this.state.allRoutes &&
              this.state.allRoutes.map((routeInfo, index) => (
                <ListItem
                  button={true}
                  key={routeInfo.id}
                  onClick={() => this.setRoute(routeInfo)}
                >
                  <Avatar
                    className={classes.avatar}
                    style={{ backgroundColor: "#" + routeInfo.color }}
                  >
                    {routeInfo.id}
                  </Avatar>
                  <ListItemText primary={routeInfo.name} />
                </ListItem>
              ))}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Map
            showMessage={this.showMessage}
            closeMessage={this.closeMessage}
            route={this.state.route && this.state.route.id}
            busRoute={this.state.busRoute}
            busVehicles={this.state.busVehicles}
            dir_selected={[
              this.state.checked1 ? this.state.dir1 : "",
              this.state.checked2 ? this.state.dir2 : ""
            ]}
          />
          {directionLoaded && (
            <CheckboxLabels
              checked1={this.state.checked1}
              checked2={this.state.checked2}
              dir1={this.state.dir1}
              dir2={this.state.dir2}
              handleChange={this.handleChange("checked1")}
              handleChange2={this.handleChange("checked2")}
            />
          )}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={this.state.open}
            onClose={this.closeMessage}
            autoHideDuration={3000}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{this.state.message}</span>}
          />
        </main>
      </div>
    );
  }

  private loadRouteData = (routeID?: number): void => {
    if (routeID) {
      fetch(`/bus_route/${routeID}`)
        .then(response => {
          response.json().then(body => {
            this.setState({ busRoute: body });
            this.extractDir(body.busStops[0].dir_abbr);
          });
        })
        .then(() => {
          fetch(`/bus_locations/${routeID}`).then(response => {
            response.json().then(body => {
              this.setState({
                busVehicles: body.map((vehicle: any) => vehicle.vehicle)
              });
            });
          });
        });
    }
  };

  private extractDir = (dirAbbr: string) => {
    switch (dirAbbr) {
      case "N":
      case "S":
        this.setState({ dir1: "N", dir2: "S" });
        break;

      case "W":
      case "E":
        this.setState({ dir1: "W", dir2: "E" });
        break;

      case "I":
      case "O":
        this.setState({ dir1: "I", dir2: "O" });
        break;

      case "K":
        this.setState({ dir1: "K", dir2: "" });
        break;

      default:
        this.setState({ dir1: "", dir2: "" });
        break;
    }
  };

  private handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (name === "checked1") {
      this.setState({ [name]: event.target.checked });
    }
    if (name === "checked2") {
      this.setState({ [name]: event.target.checked });
    }
  };

  private showMessage = (message: string) => {
    this.setState({ open: true, message });
  };

  private closeMessage = () => {
    this.setState({ open: false, message: "" });
  };
}

export const Page = withStyles(styles, { withTheme: true })(PurePage);
