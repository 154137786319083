import { BusVehicleIcon } from "./BusVehicleIcon";
//import BusVehicleIcon from "@material-ui/icons/DirectionsBus";
import React, { PureComponent } from "react";

interface BusVehiclePinProps {
  bearing: string;
  onClick(): void;
}

// TODO: make BusVehiclePin honor bearing
export class BusVehiclePin extends PureComponent<BusVehiclePinProps> {
  public render() {
    const { onClick, bearing } = this.props;

    return <BusVehicleIcon bearing={bearing} onClick={onClick} />;
  }
}
