import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer
} from "@material-ui/core";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import PlaceIcon from "@material-ui/icons/Place";
import Moment from "moment";
import * as React from "react";
import { ArrivalTime, BusStop } from "./Map";

interface BusStopDrawerProps {
  open: boolean;
  busStop: BusStop;
  arrivalTimes: ArrivalTime[];
  onClose(): void;
}

export class BusStopDrawer extends React.Component<BusStopDrawerProps, any> {
  public render() {
    const { onClose, open, arrivalTimes } = this.props;
    return (
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        onOpen={() => {}}
      >
        <List component="nav">
          <ListItem button>
            <ListItemIcon>
              <PlaceIcon />
            </ListItemIcon>
            <ListItemText primary={this.props.busStop.stopName} />
          </ListItem>
        </List>

        <Divider />
        <List>
          {arrivalTimes.map(arrivalTime => (
            <ListItem key={arrivalTime.arrival_time}>
              <ListItemIcon>
                <DirectionsBusIcon />
              </ListItemIcon>
              <ListItemText
                primary={Moment(arrivalTime.arrival_time, "kk:mm:ss").format(
                  "LT"
                )}
                secondary={Moment(
                  arrivalTime.arrival_time,
                  "kk:mm:ss"
                ).fromNow()}
              />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    );
  }
}
