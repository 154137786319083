import { createStyles, Theme, withStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import PropTypes from "prop-types";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    checked: {}
  });

interface PureCheckedBoxProps {
  checked1: boolean;
  checked2: boolean;
  dir1: string;
  dir2: string;
  handleChange(checked: React.ChangeEvent<HTMLInputElement>): void;
  handleChange2(checked: React.ChangeEvent<HTMLInputElement>): void;
}

export class CheckboxLabels extends React.Component<PureCheckedBoxProps> {
  constructor(props: PureCheckedBoxProps) {
    super(props);
  }

  public render() {
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.checked1}
              onChange={e => this.props.handleChange(e)}
              value="checked1"
            />
          }
          label={this.translateDirAbr(this.props.dir1)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.checked2}
              onChange={e => this.props.handleChange2(e)}
              value="checked2"
              color="primary"
            />
          }
          label={this.translateDirAbr(this.props.dir2)}
        />
      </FormGroup>
    );
  }

  private translateDirAbr(abr: string) {
    switch (abr) {
      case "N":
        return "Northbound";
      case "S":
        return "Southbound";
      case "W":
        return "Westbound";
      case "E":
        return "Eastbound";
      case "I":
        return "Inbound";
      case "O":
        return "Outbound";
      case "K":
        return "clockwise";
      default:
        return "undefined";
    }
  }
}

export default withStyles(styles)(CheckboxLabels);
