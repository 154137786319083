import { Dialog, DialogTitle } from "@material-ui/core";
import * as React from "react";

interface HelpDialogProps {
  open: boolean;
  onClose(): void;
}

export class HelpDialog extends React.Component<HelpDialogProps, any> {
  public render() {
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={this.props.open}
      >
        <DialogTitle id="simple-dialog-title">About Austin Bus Go</DialogTitle>
        <div>
          1) Choose the route in the drawer
          <br />
          2) Filter by direction
          <br />
          3) Click on a bus or a stop
        </div>
      </Dialog>
    );
  }
  private handleClose = () => {
    this.props.onClose();
  };
}
