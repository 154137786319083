import React, { PureComponent } from "react";
import { CanvasOverlay, CanvasRedrawOptions } from "react-map-gl";
import { Coordinate } from "./Map";

interface PolylineOverlayProps {
  points: Coordinate[];
}

export class PolylineOverlay extends PureComponent<PolylineOverlayProps> {
  public redraw = ({ width, height, ctx, project }: CanvasRedrawOptions) => {
    const { points } = this.props;
    const renderWhileDragging = true;
    const color = "#a5a5a5";
    const lineWidth = 8;
    ctx.clearRect(0, 0, width, height);
    ctx.globalCompositeOperation = "lighter";

    if (renderWhileDragging && points) {
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = color;
      ctx.lineJoin = "round";
      ctx.lineCap = "round";
      ctx.beginPath();
      points.forEach((point: Coordinate) => {
        const pixel = project([point[0], point[1]]);
        ctx.lineTo(pixel[0], pixel[1]);
      });
      ctx.stroke();
    }
  };

  public render() {
    return <CanvasOverlay redraw={this.redraw} />;
  }
}
